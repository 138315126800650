<template>
  <v-container>
    <v-card outlined>
      <v-card-title>{{ formData.name }}</v-card-title>
      <v-card-subtitle>{{ formData.description }}</v-card-subtitle>
      <v-card-text>
        <p><strong>Fecha de Creación:</strong> {{ formData.creationDate }}</p>
      </v-card-text>

      <v-card-text 
        v-for="(field, index) in formData.fields" 
        :key="field.id || index"
      >
        <ElementEngine
          :item="field"
          
        />
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import ElementEngine from './ElementEngine.vue';

export default {
  name: 'FormEngine',
  components: {
    ElementEngine
  },
  props: {
    formData: {
      type: Object,
      required: true,
      default: () => ({
        name: '',
        description: '',
        creationDate: '',
        fields: []
      })
    }
  },
  data() {
    return {
      //editableFields: [] // Contendrá solo los campos que escriben valores
    };
  },
  methods: {
    /*
    @update:itemValue="field.id ? updateFieldValue(field.id, $event) : null"
    extractEditableFields(fields) {
      let editableFields = [];

      fields.forEach((field) => {
        if (field.type === 'textarea' || 
            field.type === 'textfield' || 
            field.type === 'select' || 
            field.type === 'checkbox' || 
            field.type === 'checkbox-group' || 
            field.type === 'datefield') {
          // Asigna un ID único a cada campo editable
          editableFields.push(field);
        } else if (field.type === 'row' || field.type === 'card') {
          // Si es una estructura compleja, busca recursivamente
          editableFields = editableFields.concat(this.extractEditableFields(field.cols ? field.cols.map(col => col.value) : field.contents));
        }
      });

      return editableFields;
    },
    updateFieldValue(id, updatedItem) {
      const fieldIndex = this.editableFields.findIndex(field => field.id === id);

      if (fieldIndex !== -1) {
        this.$set(this.editableFields, fieldIndex, updatedItem);
      }
    }
    */
  }
}
</script>
