<template>
    <v-container>
        <v-btn @click="showTextarea = !showTextarea" color="primary" class="mb-4">
            {{ showTextarea ? 'Ocultar Textarea' : 'Mostrar Textarea' }}
        </v-btn>
        <v-row>
            <!-- Columna Izquierda: Textarea -->
            <v-col 
                v-if="showTextarea"
                cols="6"
            >
                <v-textarea 
                    label="Ingrese JSON" 
                    v-model="jsonInput" 
                    outlined 
                    rows="15" 
                    @input="parseJson"
                ></v-textarea>
            </v-col>
            <v-col
                v-else
                cols="12"
            >
                <v-container fluid>
                    <pre><small>{{ editables }}</small></pre>
                </v-container>
            </v-col>

            <!-- Columna Derecha: Resultado -->
            <v-col cols="12">
                <v-alert
                    v-if="error"
                    type="error"
                >
                    {{ error }}
                </v-alert>
                
                <FormEngine 
                    v-if="form.name"
                    :form-data="form" 
                />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapState } from 'vuex';
import FormEngine from './FormEngine.vue';

export default {
    components: {
        FormEngine,
    },
    data() {
        return {
            jsonInput: '',
            parsedData: null,
            error: null,
            showTextarea: true
        };
    },
    computed: {
        ...mapState('app', ['form', 'editables']),
    },
    methods: {
        parseJson() {
            try {
                const payload = JSON.parse(this.jsonInput);

                this.$store.dispatch('app/create', payload);
                this.error = null
            } catch (error) {
                // Manejo de error si el JSON es inválido
                this.parsedData = null;
                this.error = 'Error parse data'
            }
        },
    },
};
</script>

<style scoped>
pre {
  background-color: #f5f5f5;
  border-radius: 4px;
  overflow-x: auto; /* Permite scroll horizontal si es necesario */
  white-space: pre-wrap; /* Permite el ajuste de línea */
  word-break: break-word; /* Rompe palabras largas */
  overflow-y: auto; /* Scroll vertical si excede la altura máxima */
}
</style>