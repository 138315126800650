import { render, staticRenderFns } from "./TestForm.vue?vue&type=template&id=4fb0bddd&scoped=true"
import script from "./TestForm.vue?vue&type=script&lang=js"
export * from "./TestForm.vue?vue&type=script&lang=js"
import style0 from "./TestForm.vue?vue&type=style&index=0&id=4fb0bddd&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4fb0bddd",
  null
  
)

export default component.exports