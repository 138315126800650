export default {
  namespaced: true,
  state: {
    form: {
      name: null,
      description: null,
      creationDate: null,
      fields: [],
    },
    editables: [],
  },
  getters: {},
  mutations: {
    setForm(state, data) {
      state.form.name = data.name;
      state.form.description = data.description;
      state.form.creationDate = data.creationDate;

      state.form.fields.splice(0);
      data.fields.map((x) => {
        state.form.fields.push(x);
      });
    },
    setEditable(state, data) {
      const extractEditableFields = (fields) => {
        let editableFields = [];

        fields.forEach((field) => {
          const editableTypes = [
            "textfield",
            "datefield",
            "timefield",
            "textarea",
            "select",
            "checkbox",
            "checkbox-group",
            "table"
          ];

          if (editableTypes.includes(field.type)) {
            // Añade los campos editables directos
            editableFields.push(field);
          } else if (field.type === "row" && Array.isArray(field.cols)) {
            // Si el campo es un row, busca dentro de sus columnas
            field.cols.forEach((col) => {
              editableFields = editableFields.concat(
                extractEditableFields([col.value])
              );
            });
          } else if (field.type === "card" && Array.isArray(field.contents)) {
            // Si el campo es un card, busca dentro de sus contenidos
            editableFields = editableFields.concat(
              extractEditableFields(field.contents)
            );
          }
        });

        return editableFields;
      };

      const editableFields = extractEditableFields(data.fields);

      state.editables.splice(0);
      state.editables.push(...editableFields);
    },
    setValue(state, { id, value, name, parent }) {
      
      if (parent) {
        const fieldIndex = state.editables
          .findIndex((field) => field.id === parent);

        const { items } = state.editables[fieldIndex];
        const item = items.find(x => x.id === id);

        item[name] = value;
      } else {
        const fieldIndex = state.editables
          .findIndex((field) => field.id === id);

        if (fieldIndex !== -1) {
          state.editables[fieldIndex].value = value;
        } else {
          console.warn('field index not found', id);
        }
      }
    },
    addRow(state, { id, row }) {
      const tableIndex = state.editables
        .findIndex((field) => field.id === id);

      if (tableIndex !== -1) {
        const table = state.editables[tableIndex];
        const items = table.items;
        const nextIndex = items.length + 1;
        const newRow = {
          id: 'table-' + id + '-' + nextIndex,
          ...row
        };

        console.log(table);

        items.push(newRow);
      } else {
        throw new Error('table index not found');
      }
    },
    removeRow(state, { id, row }) {
      const tableIndex = state.editables
        .findIndex((field) => field.id === id);

      if (tableIndex !== -1) {
        const table = state.editables[tableIndex];
        const items = table.items;
        const index = items.findIndex(x => x.id === row);

        if (index !== -1) {
          items.splice(index, 1);
        }
      } else {
        throw new Error('table index not found');
      }
    },
  },
  actions: {
    create: (context, payload) => {
      const { commit } = context;

      commit("setForm", payload);
      commit("setEditable", payload);

      console.log(payload)
    },
    edit: (context, payload) => {
      const { commit } = context;
  
      commit("setValue", payload);
    },
    add: (context, payload) => {
      const { commit } = context;
  
      commit("addRow", payload);
    },
    remove: (context, payload) => {
      const { commit } = context;
  
      commit("removeRow", payload);
    },
  },
};
