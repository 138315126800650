<!-- ElementEngine.vue -->
<template>
  <div>
    <!-- renderiza un v-text-field si el tipo es 'textfield' -->
    <v-text-field
      v-if="item.type === 'textfield'"
      v-model="localValue"
      v-bind="item.props"
    ></v-text-field>

    <!-- renderiza un v-textarea si el tipo es 'textarea' -->
    <v-textarea
      v-else-if="item.type === 'textarea'"
      v-model="localValue"
      v-bind="item.props"
    ></v-textarea>

    <!-- renderiza un v-select si el tipo es 'select' -->
    <v-select
      v-else-if="item.type === 'select'"
      v-model="localValue"
      :items="item.items"
      v-bind="item.props"
    ></v-select>

    <!-- renderiza un v-checkbox si el tipo es 'checkbox' -->
    <v-checkbox
      v-else-if="item.type === 'checkbox'"
      v-model="localValue"
      v-bind="item.props"
    ></v-checkbox>

    <!-- renderiza múltiples v-checkbox si el tipo es 'checkbox-group' -->
    <div 
      v-else-if="item.type === 'checkbox-group'"
    >
      <v-checkbox
        v-for="(option, index) in item.options"
        :key="index"
        :label="option.label"
        :value="option.value"
        v-model="localValue"
        :multiple="true"
      ></v-checkbox>
    </div>

    <!-- renderiza múltiples radio si el tipo es 'radio-group' -->
    <v-radio-group
      v-else-if="item.type === 'radio-group'"
      v-model="localValue"
      v-bind="item.props"
    >
      <v-radio
        v-for="(option, index) in item.options"
        :key="index"
        :label="option.label"
        :value="option.value"
      ></v-radio>
    </v-radio-group>

    <!-- renderiza un v-date-field-element si el tipo es 'datefield' -->
    <v-date-field-element
      v-if="item.type === 'datefield'"
      v-model="localValue"
      v-bind="item.props"
      @update:date="setDateField"
    ></v-date-field-element>

    <!-- renderiza un v-time-field-element si el tipo es 'timefield' -->
    <v-time-field-element
      v-if="item.type === 'timefield'"
      v-model="localValue"
      v-bind="item.props"
      @update:date="setTimeField"
    ></v-time-field-element>

    <!-- renderiza parrafo si es de tipo 'paragraph' -->
    <p
      v-else-if="item.type === 'paragraph'"
    >
      {{ item.text }}
    </p>

    <!-- renderiza un v-row si el tipo es 'row' -->
    <v-row 
      v-else-if="item.type === 'row'" 
      v-bind="item.props"
    >
      <v-col
        v-for="(col, colIndex) in item.cols"
        :key="colIndex"
        :cols="col.size"
      >
        <v-element-engine :item="col.value"/>
      </v-col>
    </v-row>

    <!-- renderiza un v-card si el tipo es 'card' -->
    <v-card 
      v-else-if="item.type === 'card'" 
      v-bind="item.props"
    >
      <v-card-title 
        v-if="item.title"
      >
        {{ item.title }}
      </v-card-title>

      <v-card-subtitle 
        v-if="item.subtitle"
      >
        {{ item.subtitle }}
      </v-card-subtitle>
      
      <v-card-text>
        <v-element-engine 
          v-for="(content, index) in item.contents"
          :key="index"
          :item="content"
        />
      </v-card-text>

      <v-card-actions 
        v-if="item.actions"
      >
        <v-btn
          v-for="(action, index) in item.actions"
          :key="index"
          v-bind="action.props"
        >
          {{ action.text }}
        </v-btn>
      </v-card-actions>
    </v-card>

    <!-- renderiza un v-data-table si el tipo es 'table' -->
    <v-table-element
      v-else-if="item.type === 'table'"
      :id="item.id"
      :headers="item.headers"
      :items="item.items"
    >
    </v-table-element>

    <!-- renderiza un v-data-table si el tipo es 'table' -->
    <v-assistant-element
      v-else-if="item.type === 'assistant'"
      :id="item.id"
      :headers="item.headers"
      :items="item.items"
    >
    </v-assistant-element>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  components: {
    VTableElement: () => import('./TableElement.vue'),
    VAssistantElement: () => import('./AssistantElement.vue'),
    VDateFieldElement: () => import('./DateFieldElement.vue'),
    VTimeFieldElement: () => import('./TimeFieldElement.vue'),
    VElementEngine: () => import('./ElementEngine.vue') // recursive?
  },
  computed: {
    localValue: {
      get() {
        return this.item.value;
      },
      set(newValue) {
        this.$store.dispatch('app/edit', 
        { 
          id: this.item.id,
          parent: this.item.parent,
          name: this.item.name,
          value: newValue 
        });
      }
    }
  },
  methods: {
    setDateField(newValue) {
      this.$store.dispatch('app/edit', 
      { 
        id: this.item.id, 
        value: newValue 
      });
    },
    setTimeField(newValue) {
      this.$store.dispatch('app/edit', 
      { 
        id: this.item.id, 
        value: newValue 
      });
    }
  }
}
</script>
